import React from "react";

import styled from "styled-components";
import SEO from "../components/SEO";

const AboutStyles = styled.div`
  text-align: center;
  padding: 50px 15px;

  @media (min-width: 440px) {
    padding-left: 20%;
    padding-right: 20%;
  }

  p {
    padding: 15px 0;
  }
`;

const description = "Lees hoe Stetrics uit Itegem ontstaan is en hoe wij tewerk gaan om U een goede dienst verlening te voorzien in alle elektriciteitswerken en elektrische aansluiting";

const AboutPage = ({ location }) => {
  return (
    <AboutStyles id='about'>
      <SEO title='Over | Stetrics algemene elektriciteitswerken' location={location} description={description} />
      <h1>
        Over <strong>Stetrics</strong>
      </h1>
      <p>
        In 2020 zette ik de stap om te beginnen als zelfstandige in bijberoep en startte <strong>STETRICS elektriciteitswerken</strong> op als eenmanszaak.
      </p>
      <p>
        De opdrachten die ik uitvoer zijn zeer uiteenlopend. Of het nu gaat om het plaatsen van een enkel bijkomend <strong>stopcontact</strong>, het plaatsen van nieuwe{" "}
        <strong>lichtarmaturen</strong>, het conform maken aan het <strong>AREI</strong> van bestaande installaties of het volledig vernieuwen van een <strong>elektrische installatie</strong>,
      </p>
      <p>
        Ik maak graag voor iedere klant de nodige tijd en ruimte vrij om een dienstverlening op maat te kunnen aanbieden. Als zaakvoerder ben ik betrokken van bij het eerste contact tot aan de
        oplevering van de werken en draag ik een eerlijke en transparante communicatie hoog in het vaandel.
      </p>
      <p>
        Voor zowel kleine als middelgrote <strong>elektriciteitswerken</strong>
        &nbsp;kunt U voortaan terecht bij:
      </p>
      <p>
        <strong>STETRICS</strong>
      </p>
      <p>
        Jouw partner voor gestroomlijnde <strong>elektriciteitswerken</strong>
      </p>
    </AboutStyles>
  );
};

export default AboutPage;
